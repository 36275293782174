// Generated by Framer (38f2e7f)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFontsFromSharedStyle, Link, RichText, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/LlxBF921R";

const enabledGestures = {FfNGJiKcl: {hover: true}};

const serializationHash = "framer-tekFv"

const variantClassNames = {FfNGJiKcl: "framer-v-1io7h4h"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableEnumMap = {Center: "center", End: "flex-end", Start: "flex-start"}

const getProps = ({align, click, height, id, link, text, width, ...props}) => { return {...props, hA30OxyY1: link ?? props.hA30OxyY1, htCQsUnT4: text ?? props.htCQsUnT4 ?? "Comment investir", yZ8Q7N778: click ?? props.yZ8Q7N778, ZLH0NnTS2: humanReadableEnumMap[align] ?? align ?? props.ZLH0NnTS2 ?? "center"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;text?: string;align?: keyof typeof humanReadableEnumMap;click?: any;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, htCQsUnT4, ZLH0NnTS2, yZ8Q7N778, hA30OxyY1, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "FfNGJiKcl", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap9kavo3 = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (yZ8Q7N778) {const res = await yZ8Q7N778(...args);
if (res === false) return false;}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={hA30OxyY1} openInNewTab smoothScroll><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-1io7h4h", className, classNames)} framer-1j1tqiv`} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"FfNGJiKcl"} onTap={onTap9kavo3} ref={ref ?? ref1} style={{"--lkueht": ZLH0NnTS2, ...style}} {...addPropertyOverrides({"FfNGJiKcl-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-d36q30"} data-styles-preset={"LlxBF921R"} style={{"--framer-text-color": "var(--extracted-r6o4lv, var(--token-3a259a41-0d00-4102-a2f3-6fc26ea1e8f1, rgb(115, 115, 115)))"}}>Comment investir</motion.p></React.Fragment>} className={"framer-wmq35k"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"YIsH6QQcJ"} style={{"--extracted-r6o4lv": "var(--token-3a259a41-0d00-4102-a2f3-6fc26ea1e8f1, rgb(115, 115, 115))"}} text={htCQsUnT4} variants={{"FfNGJiKcl-hover": {"--extracted-r6o4lv": "var(--token-33722da1-56ef-4815-82ed-442105eb06b1, rgb(0, 0, 0))"}}} verticalAlignment={"top"} withExternalLayout {...addPropertyOverrides({"FfNGJiKcl-hover": {children: <React.Fragment><motion.p className={"framer-styles-preset-d36q30"} data-styles-preset={"LlxBF921R"} style={{"--framer-text-color": "var(--extracted-r6o4lv, var(--token-33722da1-56ef-4815-82ed-442105eb06b1, rgb(0, 0, 0)))"}}>Comment investir</motion.p></React.Fragment>}}, baseVariant, gestureVariant)}/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-tekFv.framer-1j1tqiv, .framer-tekFv .framer-1j1tqiv { display: block; }", ".framer-tekFv.framer-1io7h4h { align-content: var(--lkueht); align-items: var(--lkueht); cursor: pointer; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 14px 10px 14px 10px; position: relative; text-decoration: none; width: min-content; }", ".framer-tekFv .framer-wmq35k { flex: none; height: auto; overflow: visible; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-tekFv.framer-1io7h4h { gap: 0px; } .framer-tekFv.framer-1io7h4h > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-tekFv.framer-1io7h4h > :first-child { margin-top: 0px; } .framer-tekFv.framer-1io7h4h > :last-child { margin-bottom: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 50.5
 * @framerIntrinsicWidth 153
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"Tx84gj8mI":{"layout":["auto","auto"]}}}
 * @framerVariables {"htCQsUnT4":"text","ZLH0NnTS2":"align","yZ8Q7N778":"click","hA30OxyY1":"link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerHxP2Hihtm: React.ComponentType<Props> = withCSS(Component, css, "framer-tekFv") as typeof Component;
export default FramerHxP2Hihtm;

FramerHxP2Hihtm.displayName = "Menu Item";

FramerHxP2Hihtm.defaultProps = {height: 50.5, width: 153};

addPropertyControls(FramerHxP2Hihtm, {htCQsUnT4: {defaultValue: "Comment investir", title: "Text", type: ControlType.String}, ZLH0NnTS2: {defaultValue: "center", options: ["flex-start", "center", "flex-end"], optionTitles: ["Start", "Center", "End"], title: "Align", type: ControlType.Enum}, yZ8Q7N778: {title: "Click", type: ControlType.EventHandler}, hA30OxyY1: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramerHxP2Hihtm, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})